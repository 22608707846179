import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Icons import
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded';
import HomeIcon from '@mui/icons-material/Home';
import PolicyIcon from '@mui/icons-material/Policy';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { PATHS } from '../constants';
import { navigate } from '../store/navigator';
import { useState } from 'react';

interface ListProp {
  id: string;
  label: string;
  Icon: any;
  href?: string;
  action?: any;
}

const list: ListProp[] = [
  { id: 'home', label: 'Home', Icon: HomeIcon, href: PATHS.home },
  // { id: 'aboutUs', label: 'About Us', Icon: HomeIcon, href: PATHS.home },
  { id: 'policy', label: 'Policy', Icon: PolicyIcon, href: PATHS.policy },
  { id: 'usage', label: 'Disclaimer', Icon: DataUsageIcon, href: PATHS.usage },
  { id: 'contactUs', label: 'Contacts', Icon: ContactPageIcon, action: 'mailto:aqardothomes@gmail.com' },
];

export default function EmailNav() {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => setOpen((prevState) => !prevState);

  const Icon = open ? KeyboardArrowDownRoundedIcon : KeyboardArrowRightRounded;

  // return (
  //   <Accordion>
  //     <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
  //       <Typography>Main menu</Typography>
  //     </AccordionSummary>
  //     <AccordionDetails>
  //       <List
  //         aria-labelledby='nav-list-browse'
  //         sx={{
  //           '& .JoyListItemButton-root': { p: '8px' },
  //         }}
  //       >
  //         {list.map((l) => (
  //           <ListItem key={l.id}>
  //             <ListItemButton onClick={() => (l.action ? l.action : navigate(l.href || ''))}>
  //               <ListItemDecorator sx={{ color: 'inherit' }}>
  //                 <l.Icon fontSize='small' />
  //               </ListItemDecorator>
  //               <ListItemContent>{l.label}</ListItemContent>
  //             </ListItemButton>
  //           </ListItem>
  //         ))}
  //       </List>
  //     </AccordionDetails>
  //   </Accordion>
  // );

  return (
    <List size='sm' sx={{ '--List-item-radius': '8px' }}>
      <ListItem nested>
        <ListSubheader>
          Main menu
          <IconButton
            size='sm'
            variant='plain'
            color='primary'
            sx={{ '--IconButton-size': '24px', ml: 'auto' }}
            onClick={toggleOpen}
          >
            <Icon fontSize='small' color='primary' />
          </IconButton>
        </ListSubheader>
        <List
          aria-labelledby='nav-list-browse'
          sx={{
            '& .JoyListItemButton-root': { p: '8px' },
          }}
        >
          {open &&
            list.map((l) => (
              <ListItem key={l.id}>
                <ListItemButton onClick={() => (l.action ? l.action : navigate(l.href || ''))}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                    <l.Icon fontSize='small' />
                  </ListItemDecorator>
                  <ListItemContent>{l.label}</ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </ListItem>
    </List>
  );
}
