import { combineReducers, CombinedState, createAction, AnyAction } from '@reduxjs/toolkit';

import { AppState } from './types';

import { appReducer, appInitState } from './app/app.reducer';

import { resetAllStateAction } from './actions';

export { appInitState } from './app/app.reducer';

export type State = CombinedState<{
  app: AppState;
}>;

export const initialState: State = {
  app: appInitState,
};

const rootReducer = combineReducers<State, AnyAction>({
  app: appReducer,
});

export default (state: State = initialState, action: AnyAction) => {
  if (action.type === resetAllStateAction.toString()) {
    // return rootReducer(initialState, action);
  }
  return rootReducer(state, action);
};
