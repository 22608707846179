import { v4 as uuidv4 } from 'uuid';
import { RouteProps } from '../../constants';

export interface AppState {
  isAppReady: boolean;
  loading: number;
  tokenInfo: TokenDto;
  redirectUrl: string;
  alerts: AlertProps[];
  activeRoute: RouteProps;
  drawerOpen: boolean;
}

export interface LoginDto {
  username: string;
  password: string;
}

export interface TokenDto {
  token: string;
  userId: string;
  refreshToken?: string;
  active: boolean;
}

export interface RefreshTokenDto {
  refreshToken: string;
  userId: string;
}

export enum AlertType {
  ERROR = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface AlertProps {
  id: string;
  message: string;
  type: AlertType;
}

export class Alert implements AlertProps {
  id: string;
  message: string;
  type: AlertType;
  constructor(msg: string, type = AlertType.ERROR) {
    this.message = msg;
    this.type = type;
    this.id = uuidv4();
  }
}

export interface GameOptions {
  gameType: GameType;
  samples: number;
  playType: PlayType;
  excludedM?: string;
}

export enum PlayType {
  GENERIC = 'generic',
  LAST_SEEN = 'lastSeen',
  PURE_RANDOM = 'pureRandom',
}

export enum GameType {
  MEGAMILLION = 'megaMillion',
  POWERBALL = 'powerBall',
}

export interface Generator {
  callback?: (lists: number[][]) => void;
  options: GameOptions;
}
