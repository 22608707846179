import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

import appTheme from '../theme';

export default function ThemeComponent({ children }: any) {
  return (
    <CssVarsProvider disableTransitionOnChange theme={appTheme}>
      <CssBaseline />
      {children}
    </CssVarsProvider>
  );
}
