export function getFnName(d: any) {
  const error: any = new Error();
  const firefoxMatch = (error.stack.split('\n')[0 + d]?.match(/^.*(?=@)/) || [])[0];
  const chromeMatch = ((((error.stack.split('at ') || [])[1 + d] || '')?.match(/(^|\.| <| )(.*[^(<])( \()/) || [])[2] || '')
    .split('.')
    .pop();
  const safariMatch = error.stack.split('\n')[0 + d];

  // firefoxMatch ? console.log('firefoxMatch', firefoxMatch) : void 0;
  // chromeMatch ? console.log('chromeMatch', chromeMatch) : void 0;
  // safariMatch ? console.log('safariMatch', safariMatch) : void 0;

  return firefoxMatch || chromeMatch || safariMatch;
}

export const Logger =
  (name: string) =>
  (...vals: any[]) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`%c Aqar:${name}:`, 'color: #bada55', ...vals);
    }
  };

export const isValidEmail = (emailValue: string): [boolean, string] => {
  let valid = true;
  let error = '';

  if (!emailValue) {
    valid = false;
    error = 'Required field!';
  }
  // eslint-disable-next-line no-useless-escape
  else if (!/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(emailValue)) {
    valid = false;
    error = 'Invalid email address!';
  }
  return [valid, error];
};

export function Log(param: Record<string, any>) {
  if ((window as any).gtag) {
    const _gtag = (window as any).gtag;
    _gtag('event', 'generate_random_number', {
      ...param,
    });
  }
}
