import { Box } from '@mui/joy';
import React from 'react';

function RenderRow({ row }: { row: number[] }) {
  const cells = row.map((r, i) => {
    if (i < 5) {
      return (
        <div key={i} style={{ padding: '4px 6px', display: 'table-cell' }}>
          {r}
        </div>
      );
    }
    return (
      <div key={i} style={{ padding: '4px 6px', display: 'table-cell' }}>
        <span style={{ color: '#FF0000', fontWeight: 'bold', textDecoration: 'underline' }}>{r}</span>
      </div>
    );
  });
  return <>{cells}</>;
}

export default function Result({ lists }: { lists: number[][] }) {
  const rows = lists.map((list, i) => (
    <Box key={i} sx={{ display: 'table-row', borderBottom: '1px solid #666' }}>
      <RenderRow row={list} />
    </Box>
  ));

  return (
    <Box
      sx={{
        color: '#333333',
        bgcolor: '#FFFFFF',
        border: '1px solid #aaa',
        borderRadius: '4px',
        margin: '0 -0.3em -0.3em -0.3em',
        padding: '0.3em',
      }}
    >
      {' '}
      Plays:
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ display: 'table', borderCollapse: 'collapse', m: '1em auto 1em auto' }}>{rows}</Box>
        The number in <span style={{ color: '#FF0000', fontWeight: 'bold', textDecoration: 'underline' }}>Red</span> is your Mega
        Ball
      </Box>
    </Box>
  );
}
