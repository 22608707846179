export interface Page {
  component: () => JSX.Element;
  routeProps: RouteProps;
}

export interface RouteProps {
  name: string;
  showSearch?: boolean;
  href: string;
  protected?: boolean;
  title?: string;
  description?: string;
}

export const PATHS: Record<string, string> = {
  home: '/',
  generator: '/generator',
  faq: '/faq',
  login: '/login',
  aboutUs: '/about-us',
  policy: '/policy',
  usage: '/usage',
};
