import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { RouteProps, APP_CONFIG } from '../constants';
import { appActions } from '../store/actions';
import { Logger } from '../utils';

export default function PageWrapper({ route, children }: { route: RouteProps; children: any }) {
  const logger = Logger('PageWrapper');
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    logger('app mount', route.name);
    dispatch(appActions.toggleActiveRoute(route));
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{`${APP_CONFIG.APP_TITLE}${route?.title ? '- ' + route.title : ''}`}</title>
        <meta name='description' content={APP_CONFIG.META_DESCRIPTION} />
        <meta name='keywords' content={APP_CONFIG.META_KEYWORDS} />
        <meta name='author' content={APP_CONFIG.META_AUTHOR} />
      </Helmet>
      {children}
    </>
  );
}
