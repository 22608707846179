import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthComponent from '../components/Auth';
import { SideDrawer } from '../components/Layout';
import Loading from '../components/Loading';
import Navigation from '../components/Navigation';
import ThemeComponent from '../components/Theme';
import { Page, PATHS } from '../constants';
import { appActions } from '../store/actions';
import { isNavigatorReady, setNavigation } from '../store/navigator';
import { drawerOpenSelector, isAppReadySelector } from '../store/selectors';
import { Logger } from '../utils';
import HomePage from './home.page';
import MainPage from './main.page';
import PolicyPage from './policy.page';
import Usage from './usage.page';
import PageWrapper from './wrapper.page';

const PAGES: Record<string, Page> = {
  home: {
    component: HomePage,
    routeProps: {
      name: 'home',
      href: PATHS.home,
      showSearch: true,
      protected: false,
      title: 'Lottery Number Generator',
    },
  },
  policy: {
    component: PolicyPage,
    routeProps: {
      name: 'policy',
      href: PATHS.policy,
      showSearch: false,
      protected: false,
      title: 'Policy',
    },
  },
  usage: {
    component: Usage,
    routeProps: {
      name: 'usage',
      href: PATHS.usage,
      showSearch: false,
      protected: false,
      title: 'Terms & Usage',
    },
  },
};

export function Pages() {
  const logger = Logger('PageComponent');
  const navigator = useNavigate();
  const [isReady, setIsReady] = useState<boolean>(false);
  const isAppReady = useSelector(isAppReadySelector);

  useEffect(() => {
    setNavigation(navigator);
    setIsReady(true);
  }, []);

  if (isNavigatorReady() && !isReady) {
    setIsReady(true);
  }

  logger(isAppReady, isReady, isNavigatorReady());
  if (!isReady || !isAppReady) {
    return <Loading />;
  }

  const routes = Object.values(PAGES).map((page) => {
    const routeProps = page.routeProps;
    let element = (
      <PageWrapper route={routeProps}>
        <page.component />
      </PageWrapper>
    );
    if (routeProps.protected) {
      element = (
        <PageWrapper route={routeProps}>
          <AuthComponent>
            <page.component />
          </AuthComponent>
        </PageWrapper>
      );
    }
    return <Route key={routeProps.name} path={routeProps.href} element={element} />;
  });
  return <Routes>{routes}</Routes>;
}

export default function PagesWrapper() {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(drawerOpenSelector);

  useEffect(() => {
    dispatch(appActions.initializeTheApp());
  }, []);

  return (
    <ThemeComponent>
      {drawerOpen && (
        <SideDrawer onClose={() => dispatch(appActions.toggleDrawer(false))}>
          <Navigation />
        </SideDrawer>
      )}
      <MainPage>
        <Pages />
      </MainPage>
    </ThemeComponent>
  );
}
