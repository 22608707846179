import { GameType, PlayType } from '../store/types';

export const GAME_TYPE = {
  [GameType.POWERBALL]: 'Poweball',
  [GameType.MEGAMILLION]: 'Mega Millions',
};

export const PLAY_TYPE = {
  [PlayType.GENERIC]: 'General algorithm',
  [PlayType.LAST_SEEN]: 'Upon last seen',
  [PlayType.PURE_RANDOM]: 'Random',
};
