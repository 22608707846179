import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import createStore from './store';
import './App.css';
import Pages from './pages';

const { store, persistor } = createStore();

export { store };

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className='App'>
            <Pages />
          </div>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
