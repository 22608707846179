import Box from '@mui/joy/Box';
import Generator from '../components/Generator';

export default function HomePage() {
  return (
    <Box sx={{ flexGrow: 1, mt: 5 }} width='100%'>
      <Generator />
    </Box>
  );
}
