import Container from '@mui/joy/Container';
import Grid from '@mui/joy/Grid';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/joy/Alert';
import CircularProgress from '@mui/joy/CircularProgress';
import FooterComponent from '../components/Footer';
import HeaderComponent from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { alertsSelector, isLoadingSelector, drawerOpenSelector } from '../store/selectors';
import Stack from '@mui/joy/Stack';
import { appActions } from '../store/actions';
import { IconButton } from '@mui/joy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Root, SideNav, Header, Main } from '../components/Layout';
import Navigation from '../components/Navigation';

export default function MainPage({ children }: any) {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const alerts = useSelector(alertsSelector);
  const drawerOpen = useSelector(drawerOpenSelector);

  const hideAlert = (alertId: string) => dispatch(appActions.hideAlert(alertId));

  const alersList = alerts.map((alert) => (
    <Alert
      variant='solid'
      color={alert.type}
      key={alert.id}
      endDecorator={
        <IconButton variant='plain' size='sm' color='neutral' onClick={() => hideAlert(alert.id)}>
          <CloseRoundedIcon />
        </IconButton>
      }
    >
      {alert.message}
    </Alert>
  ));

  return (
    <Root
      sx={{
        ...(drawerOpen && {
          height: '100vh',
          overflow: 'hidden',
        }),
      }}
    >
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress />
      </Backdrop>

      <HeaderComponent />

      <SideNav>
        <Navigation />
      </SideNav>
      <Main>
        <Stack sx={{ width: '100%', display: alersList.length > 0 ? 'inherit' : 'none' }} spacing={2}>
          {alersList}
        </Stack>
        {children}
      </Main>
      {/* <Grid container minWidth='xs' maxWidth='lg' direction='column' justifyContent='center' alignItems='center'>
        <Grid width='100%'>
          <HeaderComponent />
          <Stack sx={{ width: '100%' }} spacing={2}>
            {alersList}
          </Stack>
        </Grid>
        <Grid width='100%' minHeight={'calc(100vh - 100px)'}>
          {children}
        </Grid>
        <Grid width='100%'>
          <FooterComponent />
        </Grid>
      </Grid> */}
    </Root>
  );
}
