export const APP_CONFIG = {
  APP_TITLE: 'TATTER',

  META_KEYWORDS:
    'Lottery number generator with option to include favorite lucky numbers. Pick random lottery numbers for Powerball, Mega Millions, Lucky for Life, Lotto America, Cash4Life, Lotto Max, Cash 5, Pick 2, Pick 3, Pick 4, Pick 5, EuroMillions, EuroJackpot, Set for Life, Lotto UK, Thunderball, Mahzooz, Easy 6, Mega 7.',

  META_DESCRIPTION: 'random numbers generator lottery',

  META_AUTHOR: 'tatter.us',

  GA_APP: 'G-8S14MCJQ1X',
};
