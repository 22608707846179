import { AnyAction } from '@reduxjs/toolkit';
import { delay, put, takeLatest, select, call } from 'redux-saga/effects';
import { appActions, resetAllStateAction } from '../actions';
import { Alert, AlertType, TokenDto } from './app.type';
import { activeRouteSelector, redirectUrlSelector, tokenSelector } from './app.selector';
import { navigate } from '../navigator';
import { APP_CONFIG, PATHS } from '../../constants';
import { API, axios } from '../../constants/api';
import { Log, Logger } from '../../utils';

const logger = Logger('AppSaga');

export function* axiosErrorWorker(e: any) {
  const message = e?.response?.data?.message || e.message;
  yield put(appActions.showAlert(new Alert(message || '', AlertType.ERROR)));
}

export function* initTheApp() {
  try {
    // yield delay(5000);
    logger('moj init');

    yield call(authorize);
  } finally {
    yield put(appActions.setAppReady(true));
    // yield delay(5000);
    // yield put(appActions.switchLanguage(AppLocale.AR));
  }
}

export function* redirectAfterLogin() {
  const redirectUrl: string = yield select(redirectUrlSelector);
  if (redirectUrl && redirectUrl !== PATHS.login) {
    navigate(redirectUrl, true);
    yield put(appActions.setRedirectUrl(''));
  } else {
    navigate(PATHS.home, true);
  }
}

export function* loginByPassword(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    const { data }: { data: TokenDto } = yield call(axios.post, API.login, action.payload);
    yield put(appActions.setToken(data));
    yield call(redirectAfterLogin);
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* loginByToken() {
  try {
    yield put(appActions.showLoading());
    const { refreshToken, userId } = yield select(tokenSelector);
    const { data }: { data: TokenDto } = yield call(axios.post, API.refreshToken, { refreshToken, userId });
    yield put(appActions.setToken(data));
    yield call(redirectAfterLogin);
    logger('Been called');
  } catch (e) {
    yield put(appActions.resetToken());
    navigate(PATHS.login, true);
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* authorize() {
  try {
    yield put(appActions.showLoading());
    const { pathname, search } = window.location;
    const path = pathname + search;
    yield put(appActions.setRedirectUrl(path));
    const { token, refreshToken, userId } = yield select(tokenSelector);
    if (!token || !refreshToken || !userId) {
      // navigate(PAGES.login.href, true);
    } else {
      yield call(loginByToken);
    }
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* logout() {
  yield put(resetAllStateAction());
  navigate(PATHS.login, true);
}

export function* resetPassword(action: AnyAction) {
  try {
    yield put(appActions.showLoading());
    yield call(axios.post, API.resetPassword, { username: action.payload });
  } catch (e: any) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export function* toggleActiveRoute(action: AnyAction) {
  try {
    const newRoute = action.payload;
    const activeRoute: string = yield select(activeRouteSelector);
    // do with old route
    yield put(appActions.updateActiveRoute(newRoute));
    logger(`route from ${activeRoute} to ${newRoute}`);
  } catch (e) {
    logger('Error: ', e);
  }
}

export function* generateNumbers(action: AnyAction) {
  const { options, callback } = action.payload;
  try {
    yield put(appActions.showLoading());
    const { data } = yield call(axios.post, API.generator, options);
    Log({ ...options, data });
    logger(data);
    if (typeof callback === 'function') {
      callback(data);
    }
  } catch (e) {
    yield call(axiosErrorWorker, e);
  } finally {
    yield put(appActions.hideLoading());
  }
}

export default [
  takeLatest(appActions.initializeTheApp, initTheApp),
  takeLatest(appActions.authorize, authorize),
  takeLatest(appActions.loginByPassword, loginByPassword),
  takeLatest(appActions.logout, logout),
  takeLatest(appActions.resetPassword, resetPassword),
  takeLatest(appActions.toggleActiveRoute, toggleActiveRoute),
  takeLatest(appActions.generateNumbers, generateNumbers),
];
