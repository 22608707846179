import * as React from 'react';
import { CssVarsProvider, useColorScheme, useTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

import { Header } from './Layout';
import { useDispatch } from 'react-redux';
import { appActions } from '../store/actions';
import { navigate } from '../store/navigator';
import { PATHS } from '../constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import tatterImg from '../assets/imgs/logo.png';

export default function HeaderComponent() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const smAndUpper = useMediaQuery(theme.breakpoints.up('sm'));
  const imgHeight = smAndUpper ? '30px' : '25px';
  const toHome = () => navigate(PATHS.home);

  return (
    <Header>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <IconButton
          variant='outlined'
          size='sm'
          onClick={() => dispatch(appActions.toggleDrawer(true))}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <IconButton onClick={() => toHome()} variant='outlined' size='sm' sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
          <img src={tatterImg} height={imgHeight} />
        </IconButton>
        <Typography component='h1' fontWeight='xl'>
          Tatter
        </Typography>
      </Box>
    </Header>
  );
}
