import Box from '@mui/joy/Box';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Option from '@mui/joy/Option';
import { appActions } from '../store/actions';
import { GameOptions, GameType, PlayType } from '../store/types';
import Divider from '@mui/joy/Divider';
import Button from '@mui/joy/Button';
import Result from '../components/Result';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GAME_TYPE, PLAY_TYPE } from '../constants/generator';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export default function Generator() {
  const defaultOptions: GameOptions = {
    gameType: GameType.MEGAMILLION,
    playType: PlayType.GENERIC,
    samples: 5,
  };
  const [lists, setLists] = useState<number[][]>([]);
  const [options, setOptions] = useState<GameOptions>(defaultOptions);
  const dispatch = useDispatch();

  const clearResult = () => setLists([]);

  const updateOption = (key: string, value: any) => {
    clearResult();
    setOptions((vals) => ({
      ...vals,
      [key]: value,
    }));
  };

  return (
    <Card variant='outlined' sx={{ width: 320 }}>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1 },
        }}
        noValidate
        autoComplete='off'
      >
        <div>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id='game-type'>Choose a Lottery:</InputLabel>
            <Select
              labelId='game-type'
              id='game-type-select'
              label='Choose a Lottery:'
              value={options.gameType}
              onChange={(e) => updateOption('gameType', e?.target?.value)}
            >
              {Object.values(GameType).map((gtype) => (
                <MenuItem key={gtype} value={gtype}>
                  {GAME_TYPE[gtype]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id='play-type'>Choose generation method:</InputLabel>
            <Select
              labelId='play-type'
              id='play-type-select'
              label='Choose generation method:'
              value={options.playType}
              onChange={(e) => updateOption('playType', e?.target?.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <Tooltip title='We managed several algorithms to generate numbers'>
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              }
            >
              {Object.values(PlayType).map((ptype) => (
                <MenuItem key={ptype} value={ptype}>
                  {PLAY_TYPE[ptype]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type='number'
              value={options.samples}
              id='samples'
              label='Number of Plays:'
              variant='outlined'
              onChange={(e) => updateOption('samples', Math.max(1, Math.min(25, Number(e.target.value))))}
            />
          </FormControl>
          <Divider orientation='vertical' />
          <Box sx={{ display: 'flex', mb: 5 }}>
            <Button
              variant='solid'
              size='sm'
              color='neutral'
              aria-label='reset calculation'
              sx={{ mr: 'auto', fontWeight: 600 }}
              onClick={() => clearResult()}
            >
              Reset
            </Button>
            <Button
              variant='solid'
              size='sm'
              color='primary'
              aria-label='generate random numbers'
              sx={{ ml: 'auto', fontWeight: 600 }}
              onClick={() => dispatch(appActions.generateNumbers({ options, callback: setLists }))}
            >
              Generate
            </Button>
          </Box>
        </div>
        <Result lists={lists} />
      </Box>
    </Card>
  );
}
