import { createAction } from '@reduxjs/toolkit';
import { RouteProps } from '../../constants';
import { TokenDto, LoginDto, AlertProps, Generator } from './app.type';

const namespace = 'app';

export const appActions = {
  initializeTheApp: createAction(`${namespace}/initializeTheApp`),
  setAppReady: createAction<boolean>(`${namespace}/setAppReady`),
  showLoading: createAction(`${namespace}/showLoading`),
  hideLoading: createAction(`${namespace}/hideLoading`),
  setToken: createAction<TokenDto>(`${namespace}/setToken`),
  setRedirectUrl: createAction<string>(`${namespace}/setRedirectUrl`),
  loginByToken: createAction(`${namespace}/loginByToken`),
  loginByPassword: createAction<LoginDto>(`${namespace}/loginByPassword`),
  authorize: createAction(`${namespace}/authorize`),
  resetToken: createAction(`${namespace}/resetToken`),
  showAlert: createAction<AlertProps>(`${namespace}/showAlert`),
  hideAlert: createAction<string>(`${namespace}/hideAlert`),
  resetPassword: createAction<string>(`${namespace}/resetPassword`),
  logout: createAction(`${namespace}/logout`),
  toggleActiveRoute: createAction<RouteProps>(`${namespace}/toggleActiveRoute`),
  updateActiveRoute: createAction<RouteProps>(`${namespace}/updateActiveRoute`),
  generateNumbers: createAction<Generator>(`${namespace}/generateNumbers`),
  toggleDrawer: createAction<boolean>(`${namespace}/toggleDrawer`),
};
